body {
  margin: 0;
  padding: 0;
  font-family: sans-serif;
}

h1 {
  font-family: 'Roboto-Bold';
  font-color: white;
  font-size: 42px;
}

@font-face {
  font-family: 'Roboto-Black';
  src: local('Roboto-Black'), url(./Roboto-Black.woff2) format('woff2');
}

@font-face {
  font-family: 'Roboto-Bold';
  src: local('Roboto-Bold'), url(./Roboto-Bold.woff2) format('woff2');
}

@font-face {
  font-family: 'Roboto-Regular';
  src: local('Roboto-Regular'), url(./Roboto-Regular.woff2) format('woff2');
}

@font-face {
  font-family: 'Roboto-Light';
  src: local('Roboto-Light'), url(./Roboto-Light.woff2) format('woff2');
}

@font-face {
  font-family: 'Roboto-Thin';
  src: local('Roboto-Thin'), url(./Roboto-Thin.woff2) format('woff2');
}

@font-face {
  font-family: 'Roboto-Medium';
  src: local('Roboto-Medium'), url(./Roboto-Medium.woff2) format('woff2');
}


@font-face {
  font-family: 'ClanOT-Ultra';
  src: local('ClanOT-Ultra'), url(./ClanOT-Ultra.woff2) format('woff2');
}
@font-face {
  font-family: 'ClanOT-Black';
  src: local('ClanOT-Black'), url(./ClanOT-Black.woff2) format('woff2');
}
@font-face {
  font-family: 'ClanOT-Bold';
  src: local('ClanOT-Bold'), url(./ClanOT-Bold.woff2) format('woff2');
}
@font-face {
  font-family: 'ClanOT-Medium';
  src: local('ClanOT-Medium'), url(./ClanOT-Medium.woff2) format('woff2');
}
@font-face {
  font-family: 'ClanOT-News';
  src: local('ClanOT-News'), url(./ClanOT-News.woff2) format('woff2');
}


@font-face {
  font-family: 'Inter-Black';
  src: local('Inter-Black'), url(./Inter-Black.woff2) format('woff2');
}
@font-face {
  font-family: 'Inter-Bold';
  src: local('Inter-Bold'), url(./Inter-Bold.woff2) format('woff2');
}
@font-face {
  font-family: 'Inter-Medium';
  src: local('Inter-Medium'), url(./Inter-Medium.woff2) format('woff2');
}
@font-face {
  font-family: 'Inter-SemiBold';
  src: local('Inter-SemiBold'), url(./Inter-SemiBold.woff2) format('woff2');
}
@font-face {
  font-family: 'Inter-Regular';
  src: local('Inter-Regular'), url(./Inter-Regular.woff2) format('woff2');
}

@font-face {
  font-family: 'Tahoma-Regular2';
  src: local('Tahoma-Regular'), url(./Tahoma-Regular.woff2) format('woff2');
}
@font-face {
  font-family: 'Tahoma-Bold2';
  src: local('Tahoma-Bold'), url(./Tahoma-Bold.woff2) format('woff2');
}
